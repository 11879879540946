<template>
  <div class="login">
    跳转中...
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { setLocal } from '@/common/js/utils'
import { getLocal } from '@/common/js/utils'
import { loginJava, getListtoken } from '@/service/user'
export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      username: '',
      password: '',
    })

    // 核验token 是否过期，过期则走自动登录
    async function checkToken(){
      console.log('核验token start...');
      const getToken = getLocal('token')
      const { res } = await getListtoken({
        "token": getToken
      }).catch((err)=>{
          if(err.resultCode == 500){
            console.log('token过期了，走重新自动登录!')
            autoLogin()
          }
        })
      console.log(res)
      // 跳转到对应列表页
      router.push({ path: '/product-list', query: { categoryId: route.query.categoryId } })

    }

    //自动登录
    async function autoLogin() {
      console.log('执行自动登录fun...');
      const urlQuery = route.query.account
      const { account , key, province, city, county, companyName} = route.query
      console.log(route.query);
      const { data } = await loginJava({
        "loginName": account,
        "passwordMd5": key,
        "province": province,
        "city": city,
        "county": county,
        "nickName": companyName
      })
      console.log(data)
      console.log('--------------')
      setLocal('token', data)
      setLocal('phoneNum', urlQuery)
      // 需要刷新页面，否则 axios.js 文件里的 token 不会被重置
      // window.location.href = '/'
      window.location.reload()
    }
    // 初始化
    const init = async() => {
      console.log('进入中转页了...')
      console.log(location.href)
      //获取url参数，进行storage 存储准备
      const { account , key, province, city, county, companyName} = route.query
      const obj = {
        user: account,
        pwd: key,
        province,
        city,
        county,
        companyName
      }
      setLocal('userData', JSON.stringify(obj))  // 将参数进行storage 存储

      const urlQuery = route.query.account
      const tokenValue = getLocal('token')
      if(urlQuery){
        
        if(tokenValue){ //有token的时候先调用java接口判断当前token是否过期
          checkToken()
        }


        const phoneNum = getLocal('phoneNum')
        console.log('本地存储的phoneNum:' + phoneNum);
        console.log('url上的phoneNum:' + urlQuery);
        if (phoneNum !== urlQuery ) { //获取本地存储手机号与url带过来的手机号不一致 ,自动登录
          console.log('phoneNum !== urlQuery');
          autoLogin()
        }
      }

    }
    // onMounted(() => {
    //   init()
    // })
    const onLoad = () => {
      console.log('ggg');
      init()
    }
    onLoad()
    const onRefresh = () => {
      onLoad()
    }
    return {
      ...toRefs(state),
      autoLogin,
      onRefresh,
      onLoad
    }
    
  }
  
}
</script>

<style>

</style>